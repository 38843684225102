function waitForElmShadow(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)?.shadowRoot) {
      return resolve(document.querySelector(selector).shadowRoot);
    }

    const observer = new ResizeObserver(() => {
      if (document.querySelector(selector)?.shadowRoot) {
        resolve(document.querySelector(selector).shadowRoot);
        observer.disconnect();
      }
    });

    observer.observe(document.body);
  });
}
(function () {
  var $kueskiWidget = $();
  var timer = setInterval(() => {
    $kueskiWidget = $('kueskipay-widget');
    if ($kueskiWidget.length > 0) {
      clearInterval(timer);
      if (typeof KueskipayAdvertising !== 'undefined') {
        new KueskipayAdvertising().init();
      }
      waitForElmShadow('kueskipay-widget').then(() => {
        var shadow;
        var styles = document.createElement('style');

        styles.innerHTML = `
          .kp-widget-root p span,
          .kp-widget-root p span b {
            font-family: CliniqueHelveticaNeueRegular,HelveticaNeue,Sans-Serif !important;
          }
          `;
        shadow = document.querySelector('kueskipay-widget').shadowRoot;
        shadow.appendChild(styles);
      });
    }
  }, 500);
})();
